@font-face {
  font-family: 'OpenSans Regular';
  font-style: normal;
  font-weight: normal;
  src: local('OpenSans Regular'), url('../fonts/opensans-regular.woff') format('woff');
}

@font-face {
  font-family: 'OpenSans Light';
  font-style: normal;
  font-weight: normal;
  src: local('OpenSans Light'), url('../fonts/opensans-light.woff') format('woff');
}

@font-face {
  font-family: 'OpenSans Semibold';
  font-style: normal;
  font-weight: normal;
  src: local('OpenSans Semibold'), url('../fonts/opensans-semibold.woff') format('woff');
}

@font-face {
  font-family: 'OpenSans Bold';
  font-style: normal;
  font-weight: normal;
  src: local('OpenSans Bold'), url('../fonts/opensans-bold.woff') format('woff');
}