@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700");
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import './assets/styles/variables.scss';

* {
  min-height: 0;
  min-width: 0;
}


/* ===== NÃO REMOVER ===== (Bernard) */
@keyframes rodar {
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rodar {
  to {
    transform: rotate(360deg);
  }
}


// ::selection {
//   background: #ffdac9; /* WebKit/Blink Browsers */
// }
// ::-moz-selection {
//   background: #ffdac9; /* Gecko Browsers */
// }

//COLORS
:root {
  --main-bg-color: #f26522;
  --main-paragraph-color: #5d5d5d;
  --main-menu-color: #372e2a;
  --main-header-bg-color: #f5f5f5;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #F5F5F5 inset !important;
  -webkit-text-fill-color: black !important;
}

.react-tooltip-lite {
  border-radius: 10px;
  font-family: "Open Sans", sans-serif;
}

.lbl-input {
  font-size: 14px;
  color: #676363;
}



#feature .slick-dots li.slick-active button:before {
  opacity: 1;
  // color: var(--main-bg-color) !important;
}
#feature .slick-dots li button:before {
  font-size: 16px !important;
  opacity: 1 !important;
}

#feature .slick-dots {
  bottom: 12px !important;
  text-align: left !important;
}

#feature .slick-prev,
#feature .slick-next {
  color: #e1e1e1;
  top: 95%;
  z-index: 1;
  @include respond-to(mobile) {
    display: none !important;
  }
}

#feature .slick-next {
  right: 0%;
  float: right;
  color: var(--main-bg-color);

  &:before {
    display: none;
  }
  &:hover {
    color: var(--main-bg-color);
  }
  &:focus {
    color: var(--main-bg-color);
  }
}

#feature .slick-prev {
  left: auto;
  right: 2%;
  float: right;

  &:before {
    display: none;
  }
  &:hover {
    color: var(--main-bg-color);
  }
  &:focus {
    color: var(--main-bg-color);
  }
}




















//Buttons
textarea:focus,
input:focus {
  outline: none;
}
.fullOrangeBtn {
  background: var(--main-bg-color);
  width: 296px;
  border-radius: 20px;
  border: 0;
  color: #fff;
  padding: 0.6rem;
  font-size: 13px;
  font-weight: 600;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  max-width: 100%;

  @include respond-to(mobile) {
    width: 100%;
    max-width: 100%;
  }

  &:hover {
    cursor: pointer;
  }
  &:focus {
    border: none;
    outline: none;
  }
}
.fullWhiteBorderedBtn {
  background: #FFF;
  width: 296px;
  border-radius: 20px;
  border-color: var(--main-bg-color);
  color: var(--main-bg-color);
  padding: 0.6rem;
  font-size: 13px;
  font-weight: 600;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  max-width: 100%;

  @include respond-to(mobile) {
    width: 100%;
    max-width: 100%;
  }

  &:hover {
    cursor: pointer;
  }
  &:focus {
    border: none;
    outline: none;
  }
}
.fullOrangeNegativeBtn {
  background: transparent;
  width: 296px;
  border-radius: 20px;
  border: 0;
  color: var(--main-bg-color);
  padding: 0.6rem;
  font-size: 13px;
  font-weight: 600;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  max-width: 100%;

  @include respond-to(mobile) {
    width: 100%;
    max-width: 100%;
  }

  &:hover {
    cursor: pointer;
  }
  &:focus {
    border: none;
    outline: none;
  }
}
.fullWhiteBtn {
  align-items: center;
  background-color: #fff;
  border-radius: 30px;
  color: red;
  display: flex;
  justify-content: center;
  padding: 5px;
  margin-right: 4px;
  margin-left: 4px;
}
.simpleBtn {
  background: transparent;
  width: 296px;
  border-radius: 20px;
  border: 0;
  color: #fff;
  padding: 0.6rem;
  font-size: 13px;
  font-weight: 600;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  max-width: 100%;

  &:hover {
    color: var(--main-bg-color);
    cursor: pointer;
  }
  &:focus {
    border: none;
    outline: none;
  }
}

.simpleOrangeBtn {
  background: transparent;
  width: 296px;
  border-radius: 20px;
  border: 0;
  color: var(--main-bg-color);
  padding: 0.6rem;
  font-size: 13px;
  font-weight: 600;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  max-width: 100%;

  &:hover {
    color: var(--main-bg-color);
    cursor: pointer;
    text-decoration: underline;
  }
  &:focus {
    border: none;
    outline: none;
  }
}

.tab {
  background: transparent;
  width: 296px;
  border: 0;
  padding: 0.3rem;
  font-size: 13px;
  align-Items: center;
  background-Color: transparent;
  color: #f26522;
  font-Weight: 600;
  transition: 0.3s;
  max-width: 100%;
  
  &:hover {
    color: black;
    cursor: pointer;  
  }
  &:focus {
    border: none;
    outline: none;  
  }
}

//BODY
body {
  background-color: #f5f5f5;
  font-family: "Open Sans", sans-serif;
  margin: 0;
  overflow-x: hidden;
  padding: 0;
  margin: 0;
  font-weight: 100
}

.mobileShow {
  @include respond-to(notMobile) {
    display: none !important;
  }
}
.mobileHide {
  @include respond-to(mobile) {
    display: none !important;
  }
}

.mobile-show-market {
  display: none;
  @include respond-to(mobile) {
    width: 100%;
    display: flex;
    align-items: 'center';
    justify-content: 'center';
    grid-column: 3 / span 12;
    grid-template-columns: repeat(12, 1fr);
    grid-row-gap: 1.5em;
    position: relative;
  }
}

header {
  // display: grid;
  // grid-template-columns: repeat(12, 1fr);
  // grid-gap: 1em;
  margin-bottom: 60px;
  @include respond-to(mobile) {
    grid-gap: 0;
    margin-bottom: 10px;
  }
  .top-ico{
    margin-left: 40px;
    // @include respond-to(mobile) {
    //   margin-left: 40px;
    // }
  }

  .-g1 {
    grid-column: 1 / span 2;
    grid-row-start: 1;
    @include respond-to(mobile) {
      grid-column: 1 / span 1;
      grid-row-start: 1;
    }
  }
  .-g2 {
    grid-column: 7 / span 6;
    grid-row: 1;

    @include respond-to(mobile) {
      grid-column: 2 / span 1;
      grid-row-start: 1;
    }
  }
  .-g3 {
    grid-column: 2 / span 10;
  }
}


main {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  // padding-top: 2rem;
  grid-row-gap: 1.5em;
  position: relative;

  @include respond-to(mobile) {
    grid-gap: 0;
    padding-top: 0;
  }

  .-g1 {
    grid-column: 2 / span 10;
    grid-row-start: 1;
    grid-row-end: 3;

    @include respond-to(handhelds) {
      grid-column: 1 / span 15;
    }
    @include respond-to(medium-screens) {
      grid-column: 1 / span 15;
    }
  }
  .-g2 {
    grid-column: 2 / span 2;
    grid-row-start: 1;
    grid-row-end: 2;

    @include respond-to(mobile) {
    }
    @include respond-to(medium-screens) {
      display: none;
    }
  }
  .-g3 {
    grid-column: 2 / span 3;
    grid-row: 2;

    @include respond-to(handhelds) {
      grid-column: 2 / span 10;
    }
    @include respond-to(medium-screens) {
      grid-column: 2 / span 10;
      grid-row: 1;
      margin-bottom: 10%;
    }
  }
  .-g4 {
    grid-column: 6 / span 5;
    grid-row: 2;

    @include respond-to(mobile) {
      grid-column: 2 / span 10;
      height: 75vh;
      // align-content: flex-end;
    }
    @include respond-to(medium-screens) {
      grid-column: 2 / span 10;
      align-content: flex-start;
    }
    @include respond-to(landscape-screens) {
      height: 95vh;
    }
  }
  .-g5 {
    grid-column: 1 / span 12;
    text-align: right;
    // padding-top: 9rem;
    // margin-bottom: -90px;

    @include respond-to(mobile) {
      grid-column: 4 / span 8;
      text-align: right;
      padding-top: 4rem;
    }
  }
  .-g6 {
    grid-column: 1 / span 11;
    position: relative;

    @include respond-to(mobile) {
      grid-column: 2 / span 10;
      padding: 5rem 0;
      grid-row: 3;
    }
  }
  .-g7 {
    grid-column: 1 / span 12;
    text-align: left;
    padding: 3rem 0;
    // padding-top: 9rem;
  }
  .-g8 {
    grid-column: 2 / span 10;
    @include respond-to(mobile) {
      grid-row: 4;
    }
  }
  .-g9 {
    grid-column: 2 / span 10;
  }
}

.container {
  margin: 0 10%;
  display: grid;
}

//HEADER
#header {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e1e1e1;
  
  @include respond-to(mobile) {
    border-bottom: 0px;
  }

  // .navigation{
  //   font-weight: 600;
  //   text-align: right;

  //   ul {
  //     display: flex;
  //     justify-content: flex-end;
  //   }
  //   li{
  //     list-style: none;
  //     margin: 0 30px;
  //   }
  //   a{
  //     color: #372e2a;
  //     font-size: 14px;
  //     text-decoration: none;
  //     font-weight: normal;
  //   }
  // }
}

@media all and (max-width: 900px) {
  #header {
    flex-direction: column;
  }
}

//FUNDO
.backImage {
  grid-column: 7 / 12;
  background: #f5f5f5;
  background-size: contain;
  background-position: top right;
  background-repeat: no-repeat;
  z-index: -1;
  position: absolute;
  height: calc(100vh - 6rem - 14px);
  width: 100%;
  top: 0;
  -webkit-animation: drop 4s infinite;
  -moz-animation: drop 4s infinite;
  animation: drop 4s infinite;
  -webkit-animation-timing-function: ease-out;
  -moz-animation-timing-function: ease-out;
  animation-timing-function: ease-out;

  @include respond-to(mobile) {
    display: none;
  }
}

//HERO TEXT
.heroText {
  // margin: 2rem;
  grid-column: 1 / span 12;
  grid-template-columns: repeat(12, 1fr);
  grid-row-gap: 1.5em;
  position: relative;

  @media (min-width: 1366px) {
    height: calc(100vh - 5rem - 14px);
  }
  @include respond-to(medium-screens) {
    padding: 3rem 2rem;
  }

  h1 {
    font-size: 2.1vw;
    font-weight: 600;
    color: #372e2a;
    margin: 0;

    @include respond-to(mobile) {
      font-size: 1.6rem;
      font-weight: normal;
    }
  }
  
  .separator {
    height: 2px;
    width: 15%;
    background: var(--main-bg-color);
    margin: 25px 0px;
  }

  .btnStore {
    align-self: center;
    grid-column: 2 / span 3;
    @include respond-to(mobile) {
      margin-top: 2rem;
      margin-bottom: -0.8rem;
      grid-column: 2 / 12;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      align-items: center;
      align-self: center;

      & > span{
        text-align: center;
      }
    }
    @include respond-to(wide-screens-min) {
      grid-column: 2 / span 4;
    }
    
    p {
      margin-bottom: 35px;
      font-size: 20px;

      @include respond-to(mobile) {
        font-size: 12px;
      }
      @include respond-to(medium-screens) {
        font-size: 15px;
      }
    }
    .link-button {
      background: var(--main-bg-color);
      border-radius: 50px;
      width: 100%;
      height: 30px;
      color: white;
      padding: 10px 45px;
      text-decoration: none;
    }
    span{
        @include respond-to(medium-screens) {
          display: flex !important;
          flex-direction: row;
          flex-wrap: wrap;
        }
      a {
        @include respond-to(medium-screens) {
          flex: 1 0 50%;
        }
        img {
          width: auto;
          // max-width: 48%;
          height: 50px;
          margin-bottom: 1rem;
    
          @include respond-to(mobile) {
            height: auto;
            width: 90%;
          }
          @include respond-to(medium-screens) {
            height: auto;
            width: 90%;
          }
    
          &:first-child {
            margin-right: 2%
          }
        }
      }
      .flex.align-center{
        @include respond-to(medium-screens) {
          flex: 0 1 100%;  
        }   
      }
    }

  }
}

//FIRST TEXT
.firstText {
  // padding: 2rem;

  h1 {
    color: var(--main-bg-color);
    width: 100%;
    font-size: 34px;
    font-weight: lighter;
  }
  p {
    color: var(--main-paragraph-color);
    font-size: 20px;
    font-weight: 300;
    margin: 2.5rem 0;
    line-height: 33px;
  }
}

//MARCAS
.marcas {
  position: relative;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2rem 0;

  .content-marcas {
    display: flex;
    justify-content: space-between;
    max-width: 97%;
    margin: 0 auto;
    align-items: center;
  }

  .arrow-marcas {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
    opacity: 0.2;

    img {
      margin: 0;
    }
  }

  .right {
    right: 0;
  }
  .left {
    left: 0;
  }

  img {
    width: auto;
    height: 100%;
    margin: 0 auto;
  }
}

//FEATURES
.features {
  grid-column: 2 / span 12;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  @include respond-to(mobile) {
    margin: 2rem 0px;
  }
  .slick-current {
    .show {
      left: -50em;
    }
  }
  .hero {
    grid-column: 5 / span 12;
    img {
      max-width: 100%;
      visibility: hidden;

      @include respond-to(mobile) {
        visibility: visible !important;
      }
    }

    .show {
      position: absolute;
      bottom: 0;
      visibility: visible !important;
      // width: 30em;
      // height: 20em;
      -webkit-animation: slide-in 1.5s forwards;
      animation: slide-in 1.5s forwards;
    }
    @-webkit-keyframes slide-in {
      100% {
        left: 0;
      }
    }
    @keyframes slide-in {
      100% {
        left: 0;
      }
    }
  }
  .contentText {
    padding: 4rem 0;
    -webkit-flex: 1;
    flex: 1;
    align-items: flex-start;
    justify-content: flex-end;
    text-align: left;
    display: flex;
    flex-direction: column;
    @include respond-to(mobile) {
      padding: 1rem;
    }

    h1 {
      color: #372e2a;
      width: 100%;
      font-size: 34px;
      font-weight: lighter;
      @include respond-to(mobile) {
        margin: 0;
      }
    }
    p {
      color: var(--main-paragraph-color);
      display: block;
      font-size: 19px;
      font-weight: 300;
      margin: 2.5rem 0;
      a {
        color: var(--main-bg-color);
        text-decoration: none;
      }
    }
  }
  .bxCarouselContainer {
    grid-column: 5 / span 7;
    @include respond-to(mobile) {
      grid-column: 1/ 12
    }
  }
  .sliderFeaturedImage {
    position: relative;
    height: 0;
    img {
      min-width: 51vw;
      max-width: 51vw;
      position: absolute;
      left: -292%;
      z-index: 1;
      top: -120px;

      @include respond-to(medium-screens) {
        top: 0;
      }
    }
  }
  .bxCarousel {
    display: flex !important;
    position: relative;

    @include respond-to(mobile) {
      flex-direction: column;
      height: auto;
    }
  }
  
  
}

//LIST
.list {
  display: flex;
  position: relative;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: flex-start;
  text-align: left;
  padding: 2rem 0;

  .card {
    display: -webkit-flex;
    display: flex;
    padding: 2rem;
    background: #fff;
    margin: 1rem 0;

    .thumbnail {
      -webkit-flex: 1;
      flex: 1;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-align-items: center;
      align-items: center;
      text-align: left;
      display: flex;

      img {
        max-width: 50%;
      }
    }
    .card-content {
      -webkit-flex: 2 1;
      flex: 2 1;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-align-items: center;
      align-items: center;
      text-align: left;
      display: flex;

      p {
        color: var(--main-paragraph-color);
        font-size: 20px;
        font-weight: lighter;
      }
    }
  }
}

//Footer
footer {
  background: #372e2a;
  padding: 2rem 0;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 1em;
  margin-top: 3%;

  @include respond-to(mobile) {
    grid-gap: 0;
    grid-template-columns: 1fr;
  }

  .-g1 {
    grid-column: 2 / span 2;
    grid-row-start: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    @include respond-to(mobile) {
      grid-column: 1 / span 1;
      grid-row-start: 1;
      justify-content: center;
    }
  }
  .-g2 {
    grid-column: 4 / span 2;
    grid-row-start: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @include respond-to(mobile) {
      grid-column: 1 / span 1;
      grid-row-start: 2;
      justify-content: center;
    }
  }

  .logo2-bs g {
    fill: #fff;
  }
}

//PAGINA SEGURO
.seguroPage {
  padding-top: 0;
  grid-template-rows: 1fr 2fr 1fr 50px;

  @include respond-to(mobile) {
    grid-template-rows: 1fr 2fr 40px;
  }

  .backImage {
    grid-column: 1 / span 12;
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 6;
    background-size: cover;
    background-position: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.3);
    animation: none;

    @include respond-to(mobile) {
      grid-row-end: 3;
      padding-bottom: 2rem;
    }
  }

  .firstText {
    grid-column: 2 / span 3;
    grid-row-start: 2;
    grid-row-end: 3;
    margin-top: 0rem;
    h1 {
      color: #f5f5f5;
      width: 100%;
      font-size: 34px;
      font-weight: lighter;
      text-shadow: 2px 2px #00000026;

      @include respond-to(mobile) {
        font-size: 26px;
      }
    }
    a {
      @include respond-to(mobile) {
        padding-bottom: 4rem;
      }
    }

    @include respond-to(mobile) {
      // display: grid;
      // height: 90vh;
      // -webkit-align-content: flex-end;
      // align-content: flex-end;
      grid-column: 2 / span 10;
      grid-row: 1;
      display: grid;
      padding-top: 2rem;
    }
  }

  .form {
    grid-column: 8 / span 4;
    grid-row: 2 / 5;
    background: #f5f5f5;

    @include respond-to(mobile) {
      grid-column: 2 / span 10;
      grid-row: 2;
      display: grid;
    }

    .containerStepsTabs {
      background-color: #fff;
      display: flex;
      padding: 0 20px;

      .item {
        align-items: flex-start;
        flex-basis: 0;
        flex-grow: 1;
        flex: 1;
        flex-direction: row;
      }
      .textStyle {
        background-color: #fff;
        color: #5d5d5d;
        text-align: center;
        span {
          background: transparent;
          border: 1px solid #e1e1e1;
          width: 15px;
          height: 13px;
          padding: 1px 8px;
          border-radius: 100%;
          color: #e1e1e1;
        }
      }
      .complete {
        flex-grow: 10;
        text-align: left;

        @include respond-to(mobile) {
          flex-grow: 4;
        }

        span {
          background: #e1e1e1;
          color: #372e2a;
        }
      }
      .notcomplete {
      }
    }

    .formSteps {
      display: flex;
      flex-direction: column;
    }

    .formButton {
      text-align: right;
      padding: 0 20px 20px 0;

      @include respond-to(mobile) {
        padding: 0 20px;
      }
    }

    .formcontent {
      padding: 2rem 3rem;

      h3 {
        color: var(--main-bg-color);
        width: 100%;
        font-size: 34px;
        font-weight: lighter;
        margin-top: 0;
      }
      .inputForm {
        margin: 1rem 0px;
        input {
          background: transparent;
          color: #3e3e3e;
          font-size: 12px;
          padding-bottom: 10px;
          border: 0;
          border-bottom: 1px solid #dedede;
          width: 100%;

          &:focus {
            border-bottom: 1px solid var(--main-bg-color);
          }
        }
      }
    }
  }

  .marcas {
    grid-column: 2 / span 10;
    grid-row: 5;
    position: relative;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 2rem 0;

    @include respond-to(mobile) {
      margin: 0;
      grid-row: 4;
      height: auto;
    }
    
    .content-marcas {
      display: flex;
      justify-content: space-between;
      max-width: 97%;
      margin: 0 auto;
      align-items: center;
    }

    .arrow-marcas {
      font-size: 0;
      line-height: 0;
      position: absolute;
      top: 50%;
      display: block;
      width: 20px;
      height: 20px;
      padding: 0;
      -webkit-transform: translate(0, -50%);
      transform: translate(0, -50%);
      cursor: pointer;
      color: transparent;
      border: none;
      outline: none;
      background: transparent;
      opacity: 0.2;

      img {
        margin: 0;
      }
    }

    .right {
      right: 0;
    }
    .left {
      left: 0;
    }

    img {
      width: auto;
      height: 100%;
      margin: 0 auto;
    }
  }
}

//TEST MENU RESPONSIVE

.navBar {
  background-color: var(--main-header-bg-color);
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  padding: 1rem 3rem;

  @include respond-to(mobile) {
    grid-template-columns: repeat(3, 1fr);
    background-color: transparent;
  }
  @include respond-to(medium-screens) {
    grid-template-columns: repeat(3, 1fr);
  }

  .-g1 {
    grid-column: 1 / span 3;
    grid-row-start: 1;
    // align-items: center;
    display: flex;
    justify-content: flex-start;

    @include respond-to(mobile) {
      grid-column: 1/12;
      grid-row-start: 1;
    }
  }
  .-g2 {
    grid-column: 4 / span 9;
    grid-row: 1;

    @include respond-to(mobile) {
      grid-column: 1 / span 3;
      grid-row-start: 2;
    }
    @include respond-to(medium-screens) {
      grid-column: 1 / span 3;
      grid-row-start: 1;
    }
  }
  .-g3 {
    grid-column: 2 / span 10;
  }
}
// Content Wrapper
.wrapper {
  margin: 0 auto;
  max-width: 960px;
  padding: 0 2%;
}

// Full Navigation, including mobile menu
.navBar {
  ul {
    display: flex;
    list-style-type: none;
    justify-content: flex-end;

    a {
      color: var(--main-menu-color);
      text-decoration: none;
      transition: all 0.5s ease;

      &:hover {
        color: var(--main-bg-color);
      }
    }
  }

  li {
    color: #372e2a;
    font-size: 14px;
    text-decoration: none;
    font-weight: normal;
    list-style: none;
    margin-left: 30px;
  }

  #menu-toggle {
    display: none;
  }

  .label-toggle {
    display: none;
  }

  .wrapper {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  .menu {
    li.active a {
      color: var(--main-bg-color);
    }
    .btn-li {
      padding: 10px 35px;
      border: 1px solid var(--main-bg-color);
      border-radius: 50px;
      color: var(--main-bg-color);
      &:hover {
        background: var(--main-bg-color);
        color: var(--main-header-bg-color);
      }
    }
  }

  @media screen and (max-width: 990px) {
    .navBar {
      .menu {
        a {
          color: var(--main-menu-color);
        }
      }
    }
    .menu {
      display: block;
      height: 0vh;
      list-style-type: none;
      opacity: 0;
      text-align: center;
      width: 100%;
      visibility: hidden;
    }

    ul {
      flex-direction: column;
      display: initial;
    }

    li {
      color: var(--main-menu-color);
      display: block;
      font-size: 17px;
      padding: 1em 0;
      font-weight: lighter;
      margin-left: 0px;
    }

    #menu-toggle:checked ~ .menu {
      opacity: 1;
      height: 100%;
      visibility: visible;
      transition: all .9s ease;
    }

    .label-toggle {
      // align-items: center;
      display: flex;
      justify-content: flex-end;
      // background: linear-gradient(to bottom, $color1 0%, $color1 20%, transparent 20%, transparent 40%, $color1 40%, $color1 60%, transparent 60%, transparent 80%, $color1 80%, $color1 100%); //Gradient Hamburger
      cursor: pointer;
      grid-column: 3 / span 1;
      grid-row-start: 1;

      svg path {
        fill: var(--main-bg-color);
      }
    }

    .wrapper {
      display: block;
    }
  }
}

//DIVIDER
.divider img {
  margin-bottom: -98px;
}

.ant-notification {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  position: fixed;
  z-index: 1010;
  width: 384px;
  max-width: calc(100vw - 32px);
  margin-right: 24px;
  z-index: 9999999999;
}
.ant-notification-topLeft,
.ant-notification-bottomLeft {
  margin-right: 0;
  margin-left: 24px;
}
.ant-notification-topLeft
  .ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-bottomLeft
  .ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-topLeft
  .ant-notification-fade-appear.ant-notification-fade-appear-active,
.ant-notification-bottomLeft
  .ant-notification-fade-appear.ant-notification-fade-appear-active {
  -webkit-animation-name: NotificationLeftFadeIn;
  animation-name: NotificationLeftFadeIn;
}
.ant-notification-close-icon {
  font-size: 14px;
  cursor: pointer;
}
.ant-notification-notice {
  font-family: 'Opensans Regular';
  position: relative;
  margin-bottom: 16px;
  padding: 16px 24px;
  overflow: hidden;
  line-height: 1.5;
  background: #fff;
  border-radius: 4px;
  -webkit-box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}
.ant-notification-notice-message {
  display: inline-block;
  margin-bottom: 8px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  line-height: 24px;
}
.ant-notification-notice-message-single-line-auto-margin {
  display: block;
  width: calc(384px - 24px * 2 - 24px - 48px - 100%);
  max-width: 4px;
  background-color: transparent;
  pointer-events: none;
}
.ant-notification-notice-message-single-line-auto-margin::before {
  display: block;
  content: "";
}
.ant-notification-notice-description {
  font-size: 14px;
}
.ant-notification-notice-closable .ant-notification-notice-message {
  padding-right: 24px;
}
.ant-notification-notice-with-icon .ant-notification-notice-message {
  margin-bottom: 4px;
  margin-left: 48px;
  font-size: 16px;
}
.ant-notification-notice-with-icon .ant-notification-notice-description {
  margin-left: 48px;
  font-size: 14px;
}
.ant-notification-notice-icon {
  position: absolute;
  margin-left: 4px;
  font-size: 24px;
  line-height: 24px;
}
.anticon.ant-notification-notice-icon-success {
  color: #52c41a;
}
.anticon.ant-notification-notice-icon-info {
  color: #1890ff;
}
.anticon.ant-notification-notice-icon-warning {
  color: #faad14;
}
.anticon.ant-notification-notice-icon-error {
  color: #f5222d;
}
.ant-notification-notice-close {
  position: absolute;
  top: 16px;
  right: 22px;
  color: rgba(0, 0, 0, 0.45);
  outline: none;
}
.ant-notification-notice-close:hover {
  color: rgba(0, 0, 0, 0.67);
}
.ant-notification-notice-btn {
  float: right;
  margin-top: 16px;
}
.ant-notification .notification-fade-effect {
  -webkit-animation-duration: 0.24s;
  animation-duration: 0.24s;
  -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.ant-notification-fade-enter,
.ant-notification-fade-appear {
  opacity: 0;
  -webkit-animation-duration: 0.24s;
  animation-duration: 0.24s;
  -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}
.ant-notification-fade-leave {
  -webkit-animation-duration: 0.24s;
  animation-duration: 0.24s;
  -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}
.ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-fade-appear.ant-notification-fade-appear-active {
  -webkit-animation-name: NotificationFadeIn;
  animation-name: NotificationFadeIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}
.ant-notification-fade-leave.ant-notification-fade-leave-active {
  -webkit-animation-name: NotificationFadeOut;
  animation-name: NotificationFadeOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}
@-webkit-keyframes NotificationFadeIn {
  0% {
    left: 384px;
    opacity: 0;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}
@keyframes NotificationFadeIn {
  0% {
    left: 384px;
    opacity: 0;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}
@-webkit-keyframes NotificationLeftFadeIn {
  0% {
    right: 384px;
    opacity: 0;
  }
  100% {
    right: 0;
    opacity: 1;
  }
}
@keyframes NotificationLeftFadeIn {
  0% {
    right: 384px;
    opacity: 0;
  }
  100% {
    right: 0;
    opacity: 1;
  }
}
@-webkit-keyframes NotificationFadeOut {
  0% {
    max-height: 150px;
    margin-bottom: 16px;
    padding-top: 16px 24px;
    padding-bottom: 16px 24px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    opacity: 0;
  }
}
@keyframes NotificationFadeOut {
  0% {
    max-height: 150px;
    margin-bottom: 16px;
    padding-top: 16px 24px;
    padding-bottom: 16px 24px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    opacity: 0;
  }
}

.ant-calendar-picker-container {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  position: absolute;
  z-index: 1050;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC",
    "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
.ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-topLeft,
.ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-topRight,
.ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-topLeft,
.ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-topRight {
  -webkit-animation-name: antSlideDownIn;
  animation-name: antSlideDownIn;
}
.ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-bottomLeft,
.ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-bottomRight,
.ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-bottomLeft,
.ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-bottomRight {
  -webkit-animation-name: antSlideUpIn;
  animation-name: antSlideUpIn;
}
.ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-topLeft,
.ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-topRight {
  -webkit-animation-name: antSlideDownOut;
  animation-name: antSlideDownOut;
}
.ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-bottomLeft,
.ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-bottomRight {
  -webkit-animation-name: antSlideUpOut;
  animation-name: antSlideUpOut;
}
.ant-calendar-picker {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  outline: none;
  cursor: text;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.ant-calendar-picker i {
  display: none;
}
.ant-calendar-picker-input {
  outline: none;
}
.ant-calendar-picker-input.ant-input {
  line-height: 1.5;
}
.ant-calendar-picker-input.ant-input-sm {
  padding-top: 0;
  padding-bottom: 0;
}
.ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
  border-color: #1890ff;
}
.ant-calendar-picker:focus .ant-calendar-picker-input:not(.ant-input-disabled) {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.ant-calendar-picker-clear,
.ant-calendar-picker-icon {
  position: absolute;
  top: 50%;
  right: 12px;
  z-index: 1;
  width: 14px;
  height: 14px;
  margin-top: -7px;
  font-size: 12px;
  line-height: 14px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ant-calendar-picker-clear {
  z-index: 2;
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  background: #fff;
  cursor: pointer;
  opacity: 0;
  pointer-events: none;
}
.ant-calendar-picker-clear:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-calendar-picker:hover .ant-calendar-picker-clear {
  opacity: 1;
  pointer-events: auto;
}
.ant-calendar-picker-icon {
  display: inline-block;
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  line-height: 1;
}
.ant-calendar-picker-small .ant-calendar-picker-clear,
.ant-calendar-picker-small .ant-calendar-picker-icon {
  right: 8px;
}
.ant-calendar {
  position: relative;
  width: 280px;
  font-size: 14px;
  line-height: 1.5;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #fff;
  border-radius: 4px;
  outline: none;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-calendar-input-wrap {
  height: 34px;
  padding: 6px 10px;
  border-bottom: 1px solid #e8e8e8;
}
.ant-calendar-input {
  width: 100%;
  height: 22px;
  color: rgba(0, 0, 0, 0.65);
  background: #fff;
  border: 0;
  outline: 0;
  cursor: auto;
}
.ant-calendar-input::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-calendar-input:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-calendar-input::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-calendar-week-number {
  width: 286px;
}
.ant-calendar-week-number-cell {
  text-align: center;
}
.ant-calendar-header {
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-bottom: 1px solid #e8e8e8;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ant-calendar-header a:hover {
  color: #40a9ff;
}
.ant-calendar-header .ant-calendar-century-select,
.ant-calendar-header .ant-calendar-decade-select,
.ant-calendar-header .ant-calendar-year-select,
.ant-calendar-header .ant-calendar-month-select {
  display: inline-block;
  padding: 0 2px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  line-height: 40px;
}
.ant-calendar-header .ant-calendar-century-select-arrow,
.ant-calendar-header .ant-calendar-decade-select-arrow,
.ant-calendar-header .ant-calendar-year-select-arrow,
.ant-calendar-header .ant-calendar-month-select-arrow {
  display: none;
}
.ant-calendar-header .ant-calendar-prev-century-btn,
.ant-calendar-header .ant-calendar-next-century-btn,
.ant-calendar-header .ant-calendar-prev-decade-btn,
.ant-calendar-header .ant-calendar-next-decade-btn,
.ant-calendar-header .ant-calendar-prev-month-btn,
.ant-calendar-header .ant-calendar-next-month-btn,
.ant-calendar-header .ant-calendar-prev-year-btn,
.ant-calendar-header .ant-calendar-next-year-btn {
  position: absolute;
  top: 0;
  display: inline-block;
  padding: 0 5px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 16px;
  font-family: Arial, "Hiragino Sans GB", "Microsoft Yahei",
    "Microsoft Sans Serif", sans-serif;
  line-height: 40px;
}
.ant-calendar-header .ant-calendar-prev-century-btn,
.ant-calendar-header .ant-calendar-prev-decade-btn,
.ant-calendar-header .ant-calendar-prev-year-btn {
  left: 7px;
}
.ant-calendar-header .ant-calendar-prev-century-btn::after,
.ant-calendar-header .ant-calendar-prev-decade-btn::after,
.ant-calendar-header .ant-calendar-prev-year-btn::after {
  content: "«";
}
.ant-calendar-header .ant-calendar-next-century-btn,
.ant-calendar-header .ant-calendar-next-decade-btn,
.ant-calendar-header .ant-calendar-next-year-btn {
  right: 7px;
}
.ant-calendar-header .ant-calendar-next-century-btn::after,
.ant-calendar-header .ant-calendar-next-decade-btn::after,
.ant-calendar-header .ant-calendar-next-year-btn::after {
  content: "»";
}
.ant-calendar-header .ant-calendar-prev-month-btn {
  left: 29px;
}
.ant-calendar-header .ant-calendar-prev-month-btn::after {
  content: "‹";
}
.ant-calendar-header .ant-calendar-next-month-btn {
  right: 29px;
}
.ant-calendar-header .ant-calendar-next-month-btn::after {
  content: "›";
}
.ant-calendar-body {
  padding: 8px 12px;
}
.ant-calendar table {
  width: 100%;
  max-width: 100%;
  background-color: transparent;
  border-collapse: collapse;
}
.ant-calendar table,
.ant-calendar th,
.ant-calendar td {
  text-align: center;
  border: 0;
}
.ant-calendar-calendar-table {
  margin-bottom: 0;
  border-spacing: 0;
}
.ant-calendar-column-header {
  width: 33px;
  padding: 6px 0;
  line-height: 18px;
  text-align: center;
}
.ant-calendar-column-header .ant-calendar-column-header-inner {
  display: block;
  font-weight: normal;
}
.ant-calendar-week-number-header .ant-calendar-column-header-inner {
  display: none;
}
.ant-calendar-cell {
  height: 30px;
  padding: 3px 0;
}
.ant-calendar-date {
  display: block;
  width: 24px;
  height: 24px;
  margin: 0 auto;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  line-height: 22px;
  text-align: center;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 2px;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
}
.ant-calendar-date-panel {
  position: relative;
  outline: none;
}
.ant-calendar-date:hover {
  background: #e6f7ff;
  cursor: pointer;
}
.ant-calendar-date:active {
  color: #fff;
  background: #40a9ff;
}
.ant-calendar-today .ant-calendar-date {
  color: #1890ff;
  font-weight: bold;
  border-color: #1890ff;
}
.ant-calendar-last-month-cell .ant-calendar-date,
.ant-calendar-next-month-btn-day .ant-calendar-date {
  color: rgba(0, 0, 0, 0.25);
}
.ant-calendar-selected-day .ant-calendar-date {
  background: #d1e9ff;
}
.ant-calendar-selected-date .ant-calendar-date,
.ant-calendar-selected-start-date .ant-calendar-date,
.ant-calendar-selected-end-date .ant-calendar-date {
  color: #fff;
  background: #1890ff;
  border: 1px solid transparent;
}
.ant-calendar-selected-date .ant-calendar-date:hover,
.ant-calendar-selected-start-date .ant-calendar-date:hover,
.ant-calendar-selected-end-date .ant-calendar-date:hover {
  background: #1890ff;
}
.ant-calendar-disabled-cell .ant-calendar-date {
  position: relative;
  width: auto;
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border: 1px solid transparent;
  border-radius: 0;
  cursor: not-allowed;
}
.ant-calendar-disabled-cell .ant-calendar-date:hover {
  background: #f5f5f5;
}
.ant-calendar-disabled-cell.ant-calendar-selected-day
  .ant-calendar-date::before {
  position: absolute;
  top: -1px;
  left: 5px;
  width: 24px;
  height: 24px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  content: "";
}
.ant-calendar-disabled-cell.ant-calendar-today .ant-calendar-date {
  position: relative;
  padding-right: 5px;
  padding-left: 5px;
}
.ant-calendar-disabled-cell.ant-calendar-today .ant-calendar-date::before {
  position: absolute;
  top: -1px;
  left: 5px;
  width: 24px;
  height: 24px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  content: " ";
}
.ant-calendar-disabled-cell-first-of-row .ant-calendar-date {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.ant-calendar-disabled-cell-last-of-row .ant-calendar-date {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.ant-calendar-footer {
  padding: 0 12px;
  line-height: 38px;
  border-top: 1px solid #e8e8e8;
}
.ant-calendar-footer:empty {
  border-top: 0;
}
.ant-calendar-footer-btn {
  display: block;
  text-align: center;
}
.ant-calendar-footer-extra {
  text-align: left;
}
.ant-calendar .ant-calendar-today-btn,
.ant-calendar .ant-calendar-clear-btn {
  display: inline-block;
  margin: 0 0 0 8px;
  text-align: center;
}
.ant-calendar .ant-calendar-today-btn-disabled,
.ant-calendar .ant-calendar-clear-btn-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-calendar .ant-calendar-today-btn:only-child,
.ant-calendar .ant-calendar-clear-btn:only-child {
  margin: 0;
}
.ant-calendar .ant-calendar-clear-btn {
  position: absolute;
  top: 7px;
  right: 5px;
  display: none;
  width: 20px;
  height: 20px;
  margin: 0;
  overflow: hidden;
  line-height: 20px;
  text-align: center;
  text-indent: -76px;
}
.ant-calendar .ant-calendar-clear-btn::after {
  display: inline-block;
  width: 20px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  line-height: 1;
  text-indent: 43px;
  -webkit-transition: color 0.3s ease;
  transition: color 0.3s ease;
}
.ant-calendar .ant-calendar-clear-btn:hover::after {
  color: rgba(0, 0, 0, 0.45);
}
.ant-calendar .ant-calendar-ok-btn {
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  cursor: pointer;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  height: 32px;
  padding: 0 15px;
  color: #fff;
  background-color: #1890ff;
  border-color: #1890ff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  height: 24px;
  padding: 0 7px;
  font-size: 14px;
  border-radius: 4px;
  line-height: 22px;
}
.ant-calendar .ant-calendar-ok-btn > .anticon {
  line-height: 1;
}
.ant-calendar .ant-calendar-ok-btn,
.ant-calendar .ant-calendar-ok-btn:active,
.ant-calendar .ant-calendar-ok-btn:focus {
  outline: 0;
}
.ant-calendar .ant-calendar-ok-btn:not([disabled]):hover {
  text-decoration: none;
}
.ant-calendar .ant-calendar-ok-btn:not([disabled]):active {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.ant-calendar .ant-calendar-ok-btn.disabled,
.ant-calendar .ant-calendar-ok-btn[disabled] {
  cursor: not-allowed;
}
.ant-calendar .ant-calendar-ok-btn.disabled > *,
.ant-calendar .ant-calendar-ok-btn[disabled] > * {
  pointer-events: none;
}
.ant-calendar .ant-calendar-ok-btn-lg {
  height: 40px;
  padding: 0 15px;
  font-size: 16px;
  border-radius: 4px;
}
.ant-calendar .ant-calendar-ok-btn-sm {
  height: 24px;
  padding: 0 7px;
  font-size: 14px;
  border-radius: 4px;
}
.ant-calendar .ant-calendar-ok-btn > a:only-child {
  color: currentColor;
}
.ant-calendar .ant-calendar-ok-btn > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-calendar .ant-calendar-ok-btn:hover,
.ant-calendar .ant-calendar-ok-btn:focus {
  color: #fff;
  background-color: #40a9ff;
  border-color: #40a9ff;
}
.ant-calendar .ant-calendar-ok-btn:hover > a:only-child,
.ant-calendar .ant-calendar-ok-btn:focus > a:only-child {
  color: currentColor;
}
.ant-calendar .ant-calendar-ok-btn:hover > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-calendar .ant-calendar-ok-btn:active,
.ant-calendar .ant-calendar-ok-btn.active {
  color: #fff;
  background-color: #096dd9;
  border-color: #096dd9;
}
.ant-calendar .ant-calendar-ok-btn:active > a:only-child,
.ant-calendar .ant-calendar-ok-btn.active > a:only-child {
  color: currentColor;
}
.ant-calendar .ant-calendar-ok-btn:active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-calendar .ant-calendar-ok-btn-disabled,
.ant-calendar .ant-calendar-ok-btn.disabled,
.ant-calendar .ant-calendar-ok-btn[disabled],
.ant-calendar .ant-calendar-ok-btn-disabled:hover,
.ant-calendar .ant-calendar-ok-btn.disabled:hover,
.ant-calendar .ant-calendar-ok-btn[disabled]:hover,
.ant-calendar .ant-calendar-ok-btn-disabled:focus,
.ant-calendar .ant-calendar-ok-btn.disabled:focus,
.ant-calendar .ant-calendar-ok-btn[disabled]:focus,
.ant-calendar .ant-calendar-ok-btn-disabled:active,
.ant-calendar .ant-calendar-ok-btn.disabled:active,
.ant-calendar .ant-calendar-ok-btn[disabled]:active,
.ant-calendar .ant-calendar-ok-btn-disabled.active,
.ant-calendar .ant-calendar-ok-btn.disabled.active,
.ant-calendar .ant-calendar-ok-btn[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.ant-calendar .ant-calendar-ok-btn-disabled > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled] > a:only-child,
.ant-calendar .ant-calendar-ok-btn-disabled:hover > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled:hover > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled]:hover > a:only-child,
.ant-calendar .ant-calendar-ok-btn-disabled:focus > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled:focus > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled]:focus > a:only-child,
.ant-calendar .ant-calendar-ok-btn-disabled:active > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled:active > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled]:active > a:only-child,
.ant-calendar .ant-calendar-ok-btn-disabled.active > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled.active > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled].active > a:only-child {
  color: currentColor;
}
.ant-calendar .ant-calendar-ok-btn-disabled > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled] > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn-disabled:hover > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled:hover > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled]:hover > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn-disabled:focus > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled:focus > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled]:focus > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn-disabled:active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled:active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled]:active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn-disabled.active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled.active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-calendar .ant-calendar-ok-btn-disabled,
.ant-calendar .ant-calendar-ok-btn.disabled,
.ant-calendar .ant-calendar-ok-btn[disabled],
.ant-calendar .ant-calendar-ok-btn-disabled:hover,
.ant-calendar .ant-calendar-ok-btn.disabled:hover,
.ant-calendar .ant-calendar-ok-btn[disabled]:hover,
.ant-calendar .ant-calendar-ok-btn-disabled:focus,
.ant-calendar .ant-calendar-ok-btn.disabled:focus,
.ant-calendar .ant-calendar-ok-btn[disabled]:focus,
.ant-calendar .ant-calendar-ok-btn-disabled:active,
.ant-calendar .ant-calendar-ok-btn.disabled:active,
.ant-calendar .ant-calendar-ok-btn[disabled]:active,
.ant-calendar .ant-calendar-ok-btn-disabled.active,
.ant-calendar .ant-calendar-ok-btn.disabled.active,
.ant-calendar .ant-calendar-ok-btn[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.ant-calendar .ant-calendar-ok-btn-disabled > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled] > a:only-child,
.ant-calendar .ant-calendar-ok-btn-disabled:hover > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled:hover > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled]:hover > a:only-child,
.ant-calendar .ant-calendar-ok-btn-disabled:focus > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled:focus > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled]:focus > a:only-child,
.ant-calendar .ant-calendar-ok-btn-disabled:active > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled:active > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled]:active > a:only-child,
.ant-calendar .ant-calendar-ok-btn-disabled.active > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled.active > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled].active > a:only-child {
  color: currentColor;
}
.ant-calendar .ant-calendar-ok-btn-disabled > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled] > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn-disabled:hover > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled:hover > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled]:hover > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn-disabled:focus > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled:focus > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled]:focus > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn-disabled:active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled:active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled]:active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn-disabled.active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled.active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-calendar-range-picker-input {
  width: 44%;
  height: 99%;
  text-align: center;
  background-color: transparent;
  border: 0;
  outline: 0;
}
.ant-calendar-range-picker-input::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-calendar-range-picker-input:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-calendar-range-picker-input::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-calendar-range-picker-input[disabled] {
  cursor: not-allowed;
}
.ant-calendar-range-picker-separator {
  display: inline-block;
  min-width: 10px;
  height: 100%;
  color: rgba(0, 0, 0, 0.45);
  white-space: nowrap;
  text-align: center;
  vertical-align: top;
  pointer-events: none;
}
.ant-calendar-range {
  width: 552px;
  overflow: hidden;
}
.ant-calendar-range .ant-calendar-date-panel::after {
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
  content: ".";
}
.ant-calendar-range-part {
  position: relative;
  width: 50%;
}
.ant-calendar-range-left {
  float: left;
}
.ant-calendar-range-left .ant-calendar-time-picker-inner {
  border-right: 1px solid #e8e8e8;
}
.ant-calendar-range-right {
  float: right;
}
.ant-calendar-range-right .ant-calendar-time-picker-inner {
  border-left: 1px solid #e8e8e8;
}
.ant-calendar-range-middle {
  position: absolute;
  left: 50%;
  z-index: 1;
  height: 34px;
  margin: 1px 0 0 0;
  padding: 0 200px 0 0;
  color: rgba(0, 0, 0, 0.45);
  line-height: 34px;
  text-align: center;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  pointer-events: none;
}
.ant-calendar-range-right .ant-calendar-date-input-wrap {
  margin-left: -90px;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-range-middle {
  padding: 0 10px 0 0;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.ant-calendar-range.ant-calendar-time
  .ant-calendar-range-right
  .ant-calendar-date-input-wrap {
  margin-left: 0;
}
.ant-calendar-range .ant-calendar-input-wrap {
  position: relative;
  height: 34px;
}
.ant-calendar-range .ant-calendar-input,
.ant-calendar-range .ant-calendar-time-picker-input {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 32px;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 32px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  height: 24px;
  padding-right: 0;
  padding-left: 0;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.ant-calendar-range .ant-calendar-input::-moz-placeholder,
.ant-calendar-range .ant-calendar-time-picker-input::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-calendar-range .ant-calendar-input:-ms-input-placeholder,
.ant-calendar-range .ant-calendar-time-picker-input:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-calendar-range .ant-calendar-input::-webkit-input-placeholder,
.ant-calendar-range .ant-calendar-time-picker-input::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-calendar-range .ant-calendar-input:hover,
.ant-calendar-range .ant-calendar-time-picker-input:hover {
  border-color: #40a9ff;
  border-right-width: 1px !important;
}
.ant-calendar-range .ant-calendar-input:focus,
.ant-calendar-range .ant-calendar-time-picker-input:focus {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.ant-calendar-range .ant-calendar-input-disabled,
.ant-calendar-range .ant-calendar-time-picker-input-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-calendar-range .ant-calendar-input-disabled:hover,
.ant-calendar-range .ant-calendar-time-picker-input-disabled:hover {
  border-color: #e6d8d8;
  border-right-width: 1px !important;
}
.ant-calendar-range .ant-calendar-input[disabled],
.ant-calendar-range .ant-calendar-time-picker-input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-calendar-range .ant-calendar-input[disabled]:hover,
.ant-calendar-range .ant-calendar-time-picker-input[disabled]:hover {
  border-color: #e6d8d8;
  border-right-width: 1px !important;
}
textarea.ant-calendar-range .ant-calendar-input,
textarea.ant-calendar-range .ant-calendar-time-picker-input {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5;
  vertical-align: bottom;
  -webkit-transition: all 0.3s, height 0s;
  transition: all 0.3s, height 0s;
}
.ant-calendar-range .ant-calendar-input-lg,
.ant-calendar-range .ant-calendar-time-picker-input-lg {
  height: 40px;
  padding: 6px 11px;
  font-size: 16px;
  line-height: 40px;
}
.ant-calendar-range .ant-calendar-input-sm,
.ant-calendar-range .ant-calendar-time-picker-input-sm {
  height: 24px;
  padding: 1px 7px;
  line-height: 24px;
}
.ant-calendar-range .ant-calendar-input:focus,
.ant-calendar-range .ant-calendar-time-picker-input:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.ant-calendar-range .ant-calendar-time-picker-icon {
  display: none;
}
.ant-calendar-range.ant-calendar-week-number {
  width: 574px;
}
.ant-calendar-range.ant-calendar-week-number .ant-calendar-range-part {
  width: 286px;
}
.ant-calendar-range .ant-calendar-year-panel,
.ant-calendar-range .ant-calendar-month-panel,
.ant-calendar-range .ant-calendar-decade-panel {
  top: 34px;
}
.ant-calendar-range .ant-calendar-month-panel .ant-calendar-year-panel {
  top: 0;
}
.ant-calendar-range .ant-calendar-decade-panel-table,
.ant-calendar-range .ant-calendar-year-panel-table,
.ant-calendar-range .ant-calendar-month-panel-table {
  height: 208px;
}
.ant-calendar-range .ant-calendar-in-range-cell {
  position: relative;
  border-radius: 0;
}
.ant-calendar-range .ant-calendar-in-range-cell > div {
  position: relative;
  z-index: 1;
}
.ant-calendar-range .ant-calendar-in-range-cell::before {
  position: absolute;
  top: 4px;
  right: 0;
  bottom: 4px;
  left: 0;
  display: block;
  background: #e6f7ff;
  border: 0;
  border-radius: 0;
  content: "";
}
.ant-calendar-range .ant-calendar-footer-extra {
  float: left;
}
div.ant-calendar-range-quick-selector {
  text-align: left;
}
div.ant-calendar-range-quick-selector > a {
  margin-right: 8px;
}
.ant-calendar-range .ant-calendar-header,
.ant-calendar-range .ant-calendar-month-panel-header,
.ant-calendar-range .ant-calendar-year-panel-header {
  border-bottom: 0;
}
.ant-calendar-range .ant-calendar-body,
.ant-calendar-range .ant-calendar-month-panel-body,
.ant-calendar-range .ant-calendar-year-panel-body {
  border-top: 1px solid #e8e8e8;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-time-picker {
  top: 68px;
  z-index: 2;
  width: 100%;
  height: 207px;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-time-picker-panel {
  height: 267px;
  margin-top: -34px;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-time-picker-inner {
  height: 100%;
  padding-top: 40px;
  background: none;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-time-picker-combobox {
  display: inline-block;
  height: 100%;
  background-color: #fff;
  border-top: 1px solid #e8e8e8;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-time-picker-select {
  height: 100%;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-time-picker-select ul {
  max-height: 100%;
}
.ant-calendar-range.ant-calendar-time
  .ant-calendar-footer
  .ant-calendar-time-picker-btn {
  margin-right: 8px;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-today-btn {
  height: 22px;
  margin: 8px 12px;
  line-height: 22px;
}
.ant-calendar-range-with-ranges.ant-calendar-time .ant-calendar-time-picker {
  height: 233px;
}
.ant-calendar-range.ant-calendar-show-time-picker .ant-calendar-body {
  border-top-color: transparent;
}
.ant-calendar-time-picker {
  position: absolute;
  top: 40px;
  width: 100%;
  background-color: #fff;
}
.ant-calendar-time-picker-panel {
  position: absolute;
  z-index: 1050;
  width: 100%;
}
.ant-calendar-time-picker-inner {
  position: relative;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  font-size: 14px;
  line-height: 1.5;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  outline: none;
}
.ant-calendar-time-picker-combobox {
  width: 100%;
}
.ant-calendar-time-picker-column-1,
.ant-calendar-time-picker-column-1 .ant-calendar-time-picker-select {
  width: 100%;
}
.ant-calendar-time-picker-column-2 .ant-calendar-time-picker-select {
  width: 50%;
}
.ant-calendar-time-picker-column-3 .ant-calendar-time-picker-select {
  width: 33.33%;
}
.ant-calendar-time-picker-column-4 .ant-calendar-time-picker-select {
  width: 25%;
}
.ant-calendar-time-picker-input-wrap {
  display: none;
}
.ant-calendar-time-picker-select {
  position: relative;
  float: left;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 226px;
  overflow: hidden;
  font-size: 14px;
  border-right: 1px solid #e8e8e8;
}
.ant-calendar-time-picker-select:hover {
  overflow-y: auto;
}
.ant-calendar-time-picker-select:first-child {
  margin-left: 0;
  border-left: 0;
}
.ant-calendar-time-picker-select:last-child {
  border-right: 0;
}
.ant-calendar-time-picker-select ul {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  max-height: 206px;
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-calendar-time-picker-select li {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  width: 100%;
  height: 24px;
  margin: 0;
  padding-left: 32px;
  line-height: 24px;
  list-style: none;
  cursor: pointer;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ant-calendar-time-picker-select li:last-child::after {
  display: block;
  height: 202px;
  content: "";
}
.ant-calendar-time-picker-select li:hover {
  background: #e6f7ff;
}
li.ant-calendar-time-picker-select-option-selected {
  font-weight: bold;
  background: #f5f5f5;
}
li.ant-calendar-time-picker-select-option-disabled {
  color: rgba(0, 0, 0, 0.25);
}
li.ant-calendar-time-picker-select-option-disabled:hover {
  background: transparent;
  cursor: not-allowed;
}
.ant-calendar-time .ant-calendar-day-select {
  display: inline-block;
  padding: 0 2px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  line-height: 34px;
}
.ant-calendar-time .ant-calendar-footer {
  position: relative;
  height: auto;
}
.ant-calendar-time .ant-calendar-footer-btn {
  text-align: right;
}
.ant-calendar-time .ant-calendar-footer .ant-calendar-today-btn {
  float: left;
  margin: 0;
}
.ant-calendar-time .ant-calendar-footer .ant-calendar-time-picker-btn {
  display: inline-block;
  margin-right: 8px;
}
.ant-calendar-time .ant-calendar-footer .ant-calendar-time-picker-btn-disabled {
  color: rgba(0, 0, 0, 0.25);
}
.ant-calendar-month-panel {
  position: absolute;
  top: 1px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  background: #fff;
  border-radius: 4px;
  outline: none;
}
.ant-calendar-month-panel > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
}
.ant-calendar-month-panel-hidden {
  display: none;
}
.ant-calendar-month-panel-header {
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-bottom: 1px solid #e8e8e8;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ant-calendar-month-panel-header a:hover {
  color: #40a9ff;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-century-select,
.ant-calendar-month-panel-header .ant-calendar-month-panel-decade-select,
.ant-calendar-month-panel-header .ant-calendar-month-panel-year-select,
.ant-calendar-month-panel-header .ant-calendar-month-panel-month-select {
  display: inline-block;
  padding: 0 2px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  line-height: 40px;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-century-select-arrow,
.ant-calendar-month-panel-header .ant-calendar-month-panel-decade-select-arrow,
.ant-calendar-month-panel-header .ant-calendar-month-panel-year-select-arrow,
.ant-calendar-month-panel-header .ant-calendar-month-panel-month-select-arrow {
  display: none;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn {
  position: absolute;
  top: 0;
  display: inline-block;
  padding: 0 5px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 16px;
  font-family: Arial, "Hiragino Sans GB", "Microsoft Yahei",
    "Microsoft Sans Serif", sans-serif;
  line-height: 40px;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn {
  left: 7px;
}
.ant-calendar-month-panel-header
  .ant-calendar-month-panel-prev-century-btn::after,
.ant-calendar-month-panel-header
  .ant-calendar-month-panel-prev-decade-btn::after,
.ant-calendar-month-panel-header
  .ant-calendar-month-panel-prev-year-btn::after {
  content: "«";
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn {
  right: 7px;
}
.ant-calendar-month-panel-header
  .ant-calendar-month-panel-next-century-btn::after,
.ant-calendar-month-panel-header
  .ant-calendar-month-panel-next-decade-btn::after,
.ant-calendar-month-panel-header
  .ant-calendar-month-panel-next-year-btn::after {
  content: "»";
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn {
  left: 29px;
}
.ant-calendar-month-panel-header
  .ant-calendar-month-panel-prev-month-btn::after {
  content: "‹";
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn {
  right: 29px;
}
.ant-calendar-month-panel-header
  .ant-calendar-month-panel-next-month-btn::after {
  content: "›";
}
.ant-calendar-month-panel-body {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.ant-calendar-month-panel-footer {
  border-top: 1px solid #e8e8e8;
}
.ant-calendar-month-panel-footer .ant-calendar-footer-extra {
  padding: 0 12px;
}
.ant-calendar-month-panel-table {
  width: 100%;
  height: 100%;
  table-layout: fixed;
  border-collapse: separate;
}
.ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month {
  color: #fff;
  background: #1890ff;
}
.ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month:hover {
  color: #fff;
  background: #1890ff;
}
.ant-calendar-month-panel-cell {
  text-align: center;
}
.ant-calendar-month-panel-cell-disabled .ant-calendar-month-panel-month,
.ant-calendar-month-panel-cell-disabled .ant-calendar-month-panel-month:hover {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  cursor: not-allowed;
}
.ant-calendar-month-panel-month {
  display: inline-block;
  height: 24px;
  margin: 0 auto;
  padding: 0 8px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 24px;
  text-align: center;
  background: transparent;
  border-radius: 2px;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
}
.ant-calendar-month-panel-month:hover {
  background: #e6f7ff;
  cursor: pointer;
}
.ant-calendar-year-panel {
  position: absolute;
  top: 1px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  background: #fff;
  border-radius: 4px;
  outline: none;
}
.ant-calendar-year-panel > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
}
.ant-calendar-year-panel-hidden {
  display: none;
}
.ant-calendar-year-panel-header {
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-bottom: 1px solid #e8e8e8;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ant-calendar-year-panel-header a:hover {
  color: #40a9ff;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-century-select,
.ant-calendar-year-panel-header .ant-calendar-year-panel-decade-select,
.ant-calendar-year-panel-header .ant-calendar-year-panel-year-select,
.ant-calendar-year-panel-header .ant-calendar-year-panel-month-select {
  display: inline-block;
  padding: 0 2px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  line-height: 40px;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-century-select-arrow,
.ant-calendar-year-panel-header .ant-calendar-year-panel-decade-select-arrow,
.ant-calendar-year-panel-header .ant-calendar-year-panel-year-select-arrow,
.ant-calendar-year-panel-header .ant-calendar-year-panel-month-select-arrow {
  display: none;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn {
  position: absolute;
  top: 0;
  display: inline-block;
  padding: 0 5px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 16px;
  font-family: Arial, "Hiragino Sans GB", "Microsoft Yahei",
    "Microsoft Sans Serif", sans-serif;
  line-height: 40px;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn {
  left: 7px;
}
.ant-calendar-year-panel-header
  .ant-calendar-year-panel-prev-century-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn::after {
  content: "«";
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn {
  right: 7px;
}
.ant-calendar-year-panel-header
  .ant-calendar-year-panel-next-century-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn::after {
  content: "»";
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn {
  left: 29px;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn::after {
  content: "‹";
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn {
  right: 29px;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn::after {
  content: "›";
}
.ant-calendar-year-panel-body {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.ant-calendar-year-panel-footer {
  border-top: 1px solid #e8e8e8;
}
.ant-calendar-year-panel-footer .ant-calendar-footer-extra {
  padding: 0 12px;
}
.ant-calendar-year-panel-table {
  width: 100%;
  height: 100%;
  table-layout: fixed;
  border-collapse: separate;
}
.ant-calendar-year-panel-cell {
  text-align: center;
}
.ant-calendar-year-panel-year {
  display: inline-block;
  height: 24px;
  margin: 0 auto;
  padding: 0 8px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 24px;
  text-align: center;
  background: transparent;
  border-radius: 2px;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
}
.ant-calendar-year-panel-year:hover {
  background: #e6f7ff;
  cursor: pointer;
}
.ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year {
  color: #fff;
  background: #1890ff;
}
.ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year:hover {
  color: #fff;
  background: #1890ff;
}
.ant-calendar-year-panel-last-decade-cell .ant-calendar-year-panel-year,
.ant-calendar-year-panel-next-decade-cell .ant-calendar-year-panel-year {
  color: rgba(0, 0, 0, 0.25);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ant-calendar-decade-panel {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  background: #fff;
  border-radius: 4px;
  outline: none;
}
.ant-calendar-decade-panel-hidden {
  display: none;
}
.ant-calendar-decade-panel-header {
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-bottom: 1px solid #e8e8e8;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ant-calendar-decade-panel-header a:hover {
  color: #40a9ff;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-century-select,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-decade-select,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-year-select,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-month-select {
  display: inline-block;
  padding: 0 2px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  line-height: 40px;
}
.ant-calendar-decade-panel-header
  .ant-calendar-decade-panel-century-select-arrow,
.ant-calendar-decade-panel-header
  .ant-calendar-decade-panel-decade-select-arrow,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-year-select-arrow,
.ant-calendar-decade-panel-header
  .ant-calendar-decade-panel-month-select-arrow {
  display: none;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn {
  position: absolute;
  top: 0;
  display: inline-block;
  padding: 0 5px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 16px;
  font-family: Arial, "Hiragino Sans GB", "Microsoft Yahei",
    "Microsoft Sans Serif", sans-serif;
  line-height: 40px;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn {
  left: 7px;
}
.ant-calendar-decade-panel-header
  .ant-calendar-decade-panel-prev-century-btn::after,
.ant-calendar-decade-panel-header
  .ant-calendar-decade-panel-prev-decade-btn::after,
.ant-calendar-decade-panel-header
  .ant-calendar-decade-panel-prev-year-btn::after {
  content: "«";
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn {
  right: 7px;
}
.ant-calendar-decade-panel-header
  .ant-calendar-decade-panel-next-century-btn::after,
.ant-calendar-decade-panel-header
  .ant-calendar-decade-panel-next-decade-btn::after,
.ant-calendar-decade-panel-header
  .ant-calendar-decade-panel-next-year-btn::after {
  content: "»";
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn {
  left: 29px;
}
.ant-calendar-decade-panel-header
  .ant-calendar-decade-panel-prev-month-btn::after {
  content: "‹";
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn {
  right: 29px;
}
.ant-calendar-decade-panel-header
  .ant-calendar-decade-panel-next-month-btn::after {
  content: "›";
}
.ant-calendar-decade-panel-body {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.ant-calendar-decade-panel-footer {
  border-top: 1px solid #e8e8e8;
}
.ant-calendar-decade-panel-footer .ant-calendar-footer-extra {
  padding: 0 12px;
}
.ant-calendar-decade-panel-table {
  width: 100%;
  height: 100%;
  table-layout: fixed;
  border-collapse: separate;
}
.ant-calendar-decade-panel-cell {
  white-space: nowrap;
  text-align: center;
}
.ant-calendar-decade-panel-decade {
  display: inline-block;
  height: 24px;
  margin: 0 auto;
  padding: 0 6px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 24px;
  text-align: center;
  background: transparent;
  border-radius: 2px;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
}
.ant-calendar-decade-panel-decade:hover {
  background: #e6f7ff;
  cursor: pointer;
}
.ant-calendar-decade-panel-selected-cell .ant-calendar-decade-panel-decade {
  color: #fff;
  background: #1890ff;
}
.ant-calendar-decade-panel-selected-cell
  .ant-calendar-decade-panel-decade:hover {
  color: #fff;
  background: #1890ff;
}
.ant-calendar-decade-panel-last-century-cell .ant-calendar-decade-panel-decade,
.ant-calendar-decade-panel-next-century-cell .ant-calendar-decade-panel-decade {
  color: rgba(0, 0, 0, 0.25);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ant-calendar-month .ant-calendar-month-header-wrap {
  position: relative;
  height: 288px;
}
.ant-calendar-month .ant-calendar-month-panel,
.ant-calendar-month .ant-calendar-year-panel {
  top: 0;
  height: 100%;
}
.ant-calendar-week-number-cell {
  opacity: 0.5;
}
.ant-calendar-week-number .ant-calendar-body tr {
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-calendar-week-number .ant-calendar-body tr:hover {
  background: #e6f7ff;
}
.ant-calendar-week-number .ant-calendar-body tr.ant-calendar-active-week {
  font-weight: bold;
  background: #bae7ff;
}
.ant-calendar-week-number
  .ant-calendar-body
  tr
  .ant-calendar-selected-day
  .ant-calendar-date,
.ant-calendar-week-number
  .ant-calendar-body
  tr
  .ant-calendar-selected-day:hover
  .ant-calendar-date {
  color: rgba(0, 0, 0, 0.65);
  background: transparent;
}

.flex {
  display: flex;
  &.align-center {
    justify-content: center;
  }
  &.align-right {
    justify-content: flex-end;
  }
  &.align-left {
    justify-content: flex-start;
  }
  &.align-middle {
    align-items: center;
  }
  &.align-top{
    align-items: flex-start;
  }
  &.align-bottom {
    align-items: flex-end;
  }
}

.grid {
  display: grid;
}

.flex.column {
  display: flex;
  flex-direction: column;
  &.align-center {
    align-items: center;
  }
  &.align-right {
    align-items: flex-end;
  }
  &.align-left {
    align-items: flex-start;
  }
  &.align-middle {
    justify-content: center;
  }
  &.align-top{
    justify-content: flex-start;
  }
  &.align-bottom {
    justify-content: flex-end;
  }
}