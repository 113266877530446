// COLORS
$color-primary: #F26522;
$color-secondary: #372E2A;
$color-white: #fff;
$color-red: #ff6961;

// MEDIA QUERY

@mixin big-screen {
  @media (max-width: 1200px) { @content; }
}
@mixin medium-screen {
  @media (max-width: 992px) { @content; }
}
@mixin small-screen {
  @media (max-width: 600px) { @content; }
}


//VARIABLES
$break-small: 320px;
$break-circle: 497px;
$break-medium: 800px;
$break-nine: 996px;
$break-large: 1200px;

$break-min-landscape: 568px;
$break-max-landscape: 812px;

$break-tablet: 600px;
$small-mobile: 360px;
//MEDIAQUERY
@mixin respond-to($media) {
  @if $media == handhelds {
    @media only screen and (max-width: $break-small) {
      @content;
    }
  } @else if $media == medium-screens {
    @media only screen and (min-width: $break-medium + 1) and (max-width: $break-large - 1) {
      @content;
    }
  } @else if $media == wide-screens-min {
    @media only screen and (min-width: $break-large) {
      @content;
    }
  } @else if $media == wide-screens-max {
    @media only screen and (max-width: $break-large) {
      @content;
    }
  } @else if $media == landscape-screens {
    @media only screen and (min-width: $break-min-landscape + 1) and (max-width: $break-max-landscape - 1) {
      @content;
    }
  } @else if $media == tablet {
    @media only screen and (max-width: $break-tablet) {
      @content;
    }
  } @else if $media == mobile {
    @media only screen and (max-width: $break-medium) {
      @content;
    }
  } @else if $media == small-mobile {
    @media only screen and (max-width: $small-mobile) {
      @content;
    }
  } @else if $media == nine {
    @media only screen and (max-width: $break-nine) {
      @content;
    }
  } @else if $media == circle {
    @media only screen and (max-width: $break-circle) {
      @content;
    }
  } @else if $media == notMobile {
    @media only screen and (min-width: $break-medium + 1) {
      @content;
    }
  }
}