.razaoSocialContainer {
    display: flex;
    align-items: center;
    flex-direction: row;
}
.razaoSocialText {
    font-weight: bold;
    margin: 0 0 0 5px !important;
    font-size: 13px !important;
}
.link_footer {
    color: white
}
.link_footer:visited {
    color: white
}
.link_footer:hover {
    color: white
}
.link_footer:active {
    color: white
}